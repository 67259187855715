<template>
<div class="audio">
  <div
      class="audio-img"
      :style="bgImg"
  >
    <h2
        class="title"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="500"
    >{{title}}</h2>
  </div>
  <ProductComponent
      :categories="categoryData"
      v-if="title === 'Audio'"
  />
  <SubComponentProduct
      v-if="title !== 'Audio'"
      :categories="subProductData"
  />
  <Footer/>
</div>
</template>

<script>
import Footer from "@/components/Nav/Footer";
import ProductComponent from "@/components/ProductComponent";
import SubComponentProduct from "@/components/SubComponentProduct";
export default {
  name: "Audio",
  components: {SubComponentProduct, ProductComponent, Footer},
  data(){
    return{
      title: 'Audio',
      categoryData: [
        {
          name: 'Earphones + Headphones',
          subcategory: ['Earpods with docking case', 'Sports Wireless Earphones', 'Overhead Earphones']
        },
        {
          name: 'Portable Speakers',
          subcategory: ['Voice Assistant Speakers', 'Waterproof Wireless Speakers', 'Mini Wireless Speakers', 'Wireless Boombox Speakers']
        },
        {
          name: 'Trolley + Tower Speakers',
          subcategory: ['Wireless Trolley Speakers', 'Wireless Tower Battery Speakers', 'Wireless Tower Speakers']
        },
        {
          name: 'Soundbars',
          subcategory: ['2.0Ch Wireless Soundbars', '2.1Ch Wireless Soundbars', '2.1Ch Wireless Soundbar Systems', '5.1Ch Wireless Soundbar Systems']
        }
      ],
      subProductData: [],
      bgImg: {
        backgroundImage: `url(${require('@/assets/images/img/audio-bg.png')})`
      }
    }
  },
  mounted() {
    window.scrollTo(0,0)
    this.title =  this.subcategory_title || this.title
  },
  computed: {

  },
  watch:{

  },
  methods: {

  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.audio{
  .audio-img{
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    padding: 414px 0 77px 100px;
    .title{
      color: $white;
    }
  }
}
</style>
